export const environment =
{
    production: false,
    ENV_FLAG: 'QAS',

    subscription_key: '663b19b9e0454182947636859e7fb73f',
    basic_api_url: 'https://api.usgbc.org/qas/leed',
    rating_systems: {
        building: 'LEED V4 O+M: EB WP',
        city: 'LEED-CT',
        community: 'LEED-CM',
        district: 'LEED-DT',
        neighborhood: 'LEED-NB',
        transit: 'LEED V4 O+M: TR',
        parksmart: 'PARKSMART',
        sites: 'SITES'
    },
    logout_url: 'https://qas-arc.gbci.org/arc-user-logout',
    logout_url_jp: 'https://qas.arcjapan.jp/',
    LO_URL: 'https://leedonline-qas.usgbc.org/',
    LOV2_URL: 'https://leedonline.usgbc.org/Login.aspx',
    animation_url: 'api-qas.arconline.io/',
    FRONTEND_BASE_URL: 'https://api.usgbc.org/qas/leed',
    DEMO_FLAG: false,
    LO_PROXY_URL: 'https://leedonline-fileproxy-qas.usgbc.org',
    landingPageURL: 'https://qas-arc.gbci.org/',
    usgbc_basic_url: 'https://qv2.usgbc.org/',
    insight_url: 'https://api.usgbc.org/skanska-dev',
    v1_applicable_date: '2019-11-05',
    it_subscription_key: 'eab7f75111d84773947fc04ed4b8c959',
    all_arc_material: [4957, 4962, 5412, 5015, 5405, 5005, 5404, 4954, 5406, 5004, 5009, 5413, 5016, 5408, 5007, 5407, 4953, 5409, 5006, 5010, 5013, 5375, 4994, 4957, 5374, 4962, 4962, 5011, 5014, 4995, 4983, 4989, 5012, 4984, 5425, 5427, 5426, 5428, 5402, 5401, 5013, 5375, 5011, 5441, 4752, 5375],
    s3_bucket_url: 'https://qas.app.arconline.io',
    sso_client: '7raG4PkdV8YLNUmM7SFQ7j4rSJrQPEvI',
    auth0_url: 'https://qas-login.usgbc.org/',
    auth0_domain: 'qas-login.usgbc.org',
    accounts_page: 'https://qas-accounts.usgbc.org/',

    HUB_URL: 'https://hub.stg.hub.usgbc.net'
};
